import React, { Fragment } from 'react';
import footerlogo from '../../assets/images/footerlogo.svg';
import nounmail from '../../assets/images/nounmail.svg';
import nouncall from '../../assets/images/nouncall.svg';
import nounlocation from '../../assets/images/nounlocation.svg';
import groupF from '../../assets/images/GroupF.svg';
import grF from '../../assets/images/GrF.svg';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import {
	Container,
	Button,
	Grid,
	Box,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import { Divider } from 'antd';

const Footer = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('lg'));
	return (
		<Fragment>
			{matches ? (
				''
			) : (
				<Container
					style={{
						backgroundColor: 'rgba(77, 165, 40, 1)',
						// height: 100,
						position: 'absolute',
						marginTop: '40px',
						marginLeft: 150,
						// padding: 20,
						borderRadius: 5,
					}}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<img src={groupF} />
						<div style={{ marginLeft: 30 }}>
							<h1 style={{ color: 'white', fontSize: 36 }}>
								Ready to adventure and enjoy natural
							</h1>
							<p style={{ color: 'rgba(255, 255, 255, 0.52)' }}>
								{/* Lorem ipsum dolor sit amet, consectetur notted adipisicin */}
							</p>{' '}
						</div>
						<img src={grF} />
						<span></span>
						<Button
							style={{
								backgroundColor: 'white',
								color: 'rgba(77, 165, 40, 1)',
								padding: 15,
							}}>
							LET,s GET STARTED
						</Button>
					</div>
				</Container>
			)}
			<div
				style={{
					backgroundColor: 'rgba(29, 35, 31, 1)',
					// height: 300,
					marginTop: 100,
				}}>
				<Container style={{ padding: 60 }}>
					<Grid container style={{ marginTop: 100 }} spacing={4}>
						<Grid item xs={12} lg={3}>
							<Box>
								<Box
									style={{
										backgroundColor: 'white',
										borderRadius: 5,
										padding: 5,
									}}>
									<img
										src={
											'https://www.pngkit.com/png/detail/140-1403354_bsbr1t9a0resdcqo-logo-demo.png'
										}
									/>
								</Box>
								<p style={{ color: '#FFFFFF', fontSize: 14, marginTop: 20 }}>
									{/* MARINERS FOREX I Flights | Buses | Hotels | Tour & Forex */}
									Company
								</p>
								<Box display={'flex'} alignItems={'center'} mt={2}>
									<img src={nounmail} />
									<p style={{ marginLeft: 10, color: '#FFFFFF' }}>
										abc@gmail.com
									</p>
								</Box>
								<Box display={'flex'} alignItems={'center'} mt={2}>
									<img src={nouncall} />
									<p style={{ marginLeft: 10, color: '#FFFFFF' }}>
										{/* 1800 8890 743 */}
									</p>
								</Box>
								<Box display={'flex'} alignItems={'center'} mt={2}>
									<img src={nounlocation} />
									<p style={{ marginLeft: 10, color: '#FFFFFF' }}>
										{/* Block - B 31, First Floor, Chandigarh Citi Center Office,
										VIP Rd, Zirakpur, Punjab 140603 */}
									</p>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} lg={2}>
							<Box>
								<h1 style={{ color: '#FFFFFF' }}>PRODUCTS</h1>
							</Box>

							<Box mt={2}>
								<Link to='/flight'>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Flight Booking
									</p>
								</Link>
							</Box>
							<Box mt={1.5}>
								<Link to='/hotel'>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Hotel Booking
									</p>
								</Link>
							</Box>
							<Box mt={1.5}>
								<Link>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>Bus Booking</p>
								</Link>
							</Box>
						</Grid>
						<Grid item xs={12} lg={2}>
							<Box>
								<h1 style={{ color: '#FFFFFF' }}>MARINERS FOREX</h1>
							</Box>
							<Box mt={2}>
								<Link>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>Company Info</p>
								</Link>
							</Box>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Privacy Policy
									</p>
								</Box>
							</Link>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Company Policy
									</p>
								</Box>
							</Link>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										User Agreement
									</p>
								</Box>
							</Link>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Terms & Conditions
									</p>
								</Box>
							</Link>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>About Us</p>
								</Box>
							</Link>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>Careers</p>
								</Box>
							</Link>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>Disclaimer</p>
								</Box>
							</Link>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>Contact Us</p>
								</Box>
							</Link>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Baggage Allowance
									</p>
								</Box>
							</Link>

							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Cancellation/ Refund Policy
									</p>
								</Box>
							</Link>
						</Grid>
						<Grid item xs={12} lg={2}>
							<Box>
								<h1 style={{ color: '#FFFFFF' }}>SUPPORT</h1>
							</Box>

							<Link>
								<Box mt={2}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Customer Care
									</p>
								</Box>
							</Link>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										FeedBack Form
									</p>
								</Box>
							</Link>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>FAQ</p>
								</Box>
							</Link>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>Contact</p>
								</Box>
							</Link>
						</Grid>
						<Grid item xs={12} lg={2}>
							<Box>
								<h1 style={{ color: '#FFFFFF' }}>MF DIRECTORY</h1>
							</Box>
							<Link>
								<Box mt={2}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										International Airports
									</p>
								</Box>
							</Link>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Domestic Airlines
									</p>
								</Box>
							</Link>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										International Airlines
									</p>
								</Box>
							</Link>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										Domestic Flights
									</p>
								</Box>
							</Link>
							<Link>
								<Box mt={1.5}>
									<p style={{ color: '#B4B4B4', fontSize: 14 }}>
										International Flights
									</p>
								</Box>
							</Link>
							<Box mt={1.5}>
								<p style={{ color: '#B4B4B4', fontSize: 14 }}>
									Airports In India
								</p>
							</Box>
							<Box mt={1.5}>
								<p style={{ color: '#B4B4B4', fontSize: 14 }}>
									Domestic Bus Routes
								</p>
							</Box>
							<Box mt={1.5}>
								<p style={{ color: '#B4B4B4', fontSize: 14 }}>
									International Hotels
								</p>
							</Box>
							<Box mt={1.5}>
								<p style={{ color: '#B4B4B4', fontSize: 14 }}>
									Airline Customer care
								</p>
							</Box>
						</Grid>
					</Grid>
					<Divider style={{ backgroundColor: '#F2F2F212' }} />
					<Box>
						<p style={{ color: '#FFFFFF' }}>
							Copyright © 2024 by Musafirbazar. All Rights Reserved
						</p>
					</Box>
				</Container>
			</div>
		</Fragment>
	);
};

export default Footer;
